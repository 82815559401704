import React, { useState } from "react";
import Modal from "../../../components/Modals";
import requests from "../../../services/requests";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";

const ModalVehicleRemove = ({ actionModal, setTabModal, openModal, handleSuccess, selectedEntity }) => {
    const [loading, setLoading] = useState(false);

    return (
        <Modal
            onClose={actionModal}
            borderTitle
            size="xs:w-11/12 lg:w-3/12"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5
                        onClick={() => setTabModal("Remover campo adicional")}
                        className={`mr-6 text-roxo_oficial font-bold`}
                    >
                        Remover "{selectedEntity?.licensePlate}"
                    </h5>
                </div>
            }
        >
            <p className="mt-10 text-center">Esta ação é permanente e não poderá ser desfeita</p>
            <button
                className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10"
                onClick={() => {
                    setLoading(true);
                    requests
                        .deleteVehicle(selectedEntity?.identifier)
                        .then(() => {
                            setLoading(false);
                            toast.success(`Veículo ${selectedEntity.licensePlate} removido com sucesso!`);
                            handleSuccess();
                        })
                        .catch(() => {
                            setLoading(false);
                        });
                }}
            >
                Remover {loading && <ClipLoader size="13" color="white" />}
            </button>
        </Modal>
    );
};

export default ModalVehicleRemove;
